import * as React from "react";

const Logo = (props) => (
  <svg
    viewBox="0 0 210.00000000000003 211.55348114392723"
    className="css-1j8o68f"
    {...props}
  >
    <defs id="SvgjsDefs3793">
      <linearGradient id="SvgjsLinearGradient3798">
        <stop id="SvgjsStop3799" stopColor="#2d388a" offset={0} />
        <stop id="SvgjsStop3800" stopColor="#00aeef" offset={1} />
      </linearGradient>
    </defs>
    <g
      id="SvgjsG3794"
      featurekey="symbolFeature-0"
      transform="matrix(3.5685866703398257,0,0,3.5685866703398257,24.53531249282586,-12.243820845516325)"
      fill="url(#SvgjsLinearGradient3798)"
    >
      <polygon
        xmlns="http://www.w3.org/2000/svg"
        points="11.088,37.5 11.839,37.885 11.839,16.989 11.088,16.547 "
      />
      <polygon
        xmlns="http://www.w3.org/2000/svg"
        points="21.827,13.004 21.871,33.907 22.396,33.666 22.838,33.734 22.838,12.874 22.479,12.75 "
      />
      <polygon
        xmlns="http://www.w3.org/2000/svg"
        points="34.221,16.123 33.254,16.65 33.254,37.914 34.221,37.343 "
      />
      <g xmlns="http://www.w3.org/2000/svg">
        <polyline
          fill="none"
          points="25.129,13.072 22.479,11.988 11.825,16.345 4.42,12.326 4.42,34.499 11.947,38.584 22.396,34.325    22.561,34.325 25.103,35.35 33.008,38.584 40.537,34.499 40.537,12.326 33.13,16.345 30.469,15.257  "
        />
        <polygon points="33.008,14.083 31.224,13.353 30.469,15.257 33.13,16.345 40.537,12.326 40.537,34.499 33.008,38.584 25.103,35.35    22.561,34.325 22.396,34.325 11.947,38.584 4.42,34.499 4.42,12.326 11.825,16.345 22.479,11.988 25.129,13.072 24.068,10.425    22.479,9.775 11.947,14.083 2.372,8.884 2.372,35.717 11.825,40.847 22.479,36.489 33.13,40.847 42.584,35.717 42.584,8.884  " />
      </g>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M28.243,3.431c-2.294,0-4.157,1.861-4.157,4.157c0,0.427,0.084,0.83,0.203,1.218l-0.017,0l3.971,9.914l4.069-10.257  l-0.008,0C32.365,8.181,32.4,7.889,32.4,7.588C32.4,5.292,30.539,3.431,28.243,3.431z M28.243,9.565c-0.982,0-1.78-0.798-1.78-1.78  c0-0.982,0.798-1.781,1.78-1.781s1.781,0.798,1.781,1.781C30.024,8.767,29.226,9.565,28.243,9.565z"
      />
    </g>
    <g
      id="SvgjsG3795"
      featurekey="nameFeature-0"
      transform="matrix(1.7546791399107156,0,0,1.7546791399107156,-3.5093574431252166,140.66443954071403)"
      fill="#137dc5"
    >
      <path d="M2 40 l0 -32 l8.4 0 l0 3.2 l-4.4 0 l0 12 l3.6 0 l0 3.2 l-3.6 0 l0 13.6 l-4 0 z M13.000000000000002 23.16 l0 -15.16 l4 0 l0 15.16 c0 11.56 0 13.24 2 13.24 s2 -1.68 2 -13.24 l0 -15.16 l4 0 l0 15.16 c0 12.8 0 17.24 -6 17.24 s-6 -4.44 -6 -17.24 z M27.2 11.600000000000001 l0 -3.6 l12 0 l0 3.6 l-4 0 l0 28.4 l-4 0 l0 -28.4 l-4 0 z M41.2 23.16 c0 -11.16 0.24 -15.56 6.24 -15.56 s6.24 4.4 6.24 15.56 c0 12.8 -0.24 17.24 -6.24 17.24 s-6.24 -4.44 -6.24 -17.24 z M45.2 23.16 c0 11.56 0 13.24 2.24 13.24 s2.24 -1.68 2.24 -13.24 c0 -10.4 0 -12.36 -2.24 -12.36 s-2.24 1.96 -2.24 12.36 z M57.08 23.16 l0 -15.16 l4 0 l0 15.16 c0 11.56 0 13.24 2 13.24 s2 -1.68 2 -13.24 l0 -15.16 l4 0 l0 15.16 c0 12.8 0 17.24 -6 17.24 s-6 -4.44 -6 -17.24 z M72.88 40 l0 -32 l6.48 0 c4.12 0 5.52 2.52 5.52 7.36 c0 4.36 -0.52 6.84 -1.76 8.04 l0 0.24 c1.04 0.36 1.36 2.24 1.76 6.92 l0.8 9.44 l-4 0 l-0.52 -9.44 c-0.28 -4.76 -1 -5.76 -2.2 -5.76 l-2.08 0 l0 15.2 l-4 0 z M76.88 21.6 l2.48 0 c1.4 0 1.8 -1.4 1.8 -6.24 c0 -3.16 -0.6 -4.16 -1.8 -4.16 l-2.48 0 l0 10.4 z M88.88 40 l0 -32 l4 0 l0 32 l-4 0 z M96.08 30.240000000000002 l4.04 0 c0.08 5.16 0.48 6.16 1.92 6.16 c1.96 0 1.96 -1.64 1.96 -6 c0 -6.4 -7.92 -4.68 -7.92 -13.2 c0 -5 0 -9.6 6 -9.6 c4.44 0 5.64 2.52 5.92 8.56 l-4 0 c-0.16 -4.36 -0.64 -5.36 -1.92 -5.36 c-1.96 0 -1.96 2.16 -1.96 6.4 c0 6.2 7.96 4 7.96 13.2 c0 5.6 0 10 -6.04 10 c-4.64 0 -5.72 -2.76 -5.96 -10.16 z M109.68 11.600000000000001 l0 -3.6 l12 0 l0 3.6 l-4 0 l0 28.4 l-4 0 l0 -28.4 l-4 0 z" />
    </g>
  </svg>
);

export default Logo;
